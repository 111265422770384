/* ##### Partners Area ##### */
.HomeDemo1 .partners{
    padding: 0 0 70px;
    margin-top: 140px;
    min-height: 100%;
    transform: scale(1.2);
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(43, 43, 43));
    opacity: 1;
}
.HomeDemo1 .partner-box{
    border: 1px solid #25cbd3;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: center;
    vertical-align: middle;
    background-color: #002d3b57;
    margin-bottom: 30px;
    transition: all .3s ease-in-out;
}
.HomeDemo1 .partner-box:hover{
    background: #25cad328;
    cursor: pointer;
}